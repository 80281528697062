import { Component, ViewEncapsulation } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from '../../services/message.service';
import { PrincipalFormService } from 'src/app/pages/principal/services/principal-form.service';
import { SecurityService } from 'src/app/services/security.service';
import { AuthFacadeService } from '../services/auth-facade.service';
import { LocalStorageService } from 'src/app/shared/core/services/local.storage.service';
import { HIERARCHY_USER_INFO } from 'src/app/shared/core/constants/local.storage.constants';
import { EncryptionService } from 'src/app/shared/core/services/encryption.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

  hide: boolean = true;
  loginForm: FormGroup;
  public formSubmitted = false;
  loading: boolean = false;

  constructor(
    private messageService: MessageService,
    private router: Router,
    private authservice: AuthService,
    private readonly securityService: SecurityService,
    private principalFormService: PrincipalFormService,
    private readonly authFacadeService: AuthFacadeService,
    private readonly localStorageService: LocalStorageService,
    private readonly encryptionService: EncryptionService
  ) {
    this.loginForm = new FormGroup({
      username: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(6),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.maxLength(30),
      ]),
    });
    
    localStorage.removeItem("access");
  }

  sendData() {
    if (!this.loginForm.invalid) {
      this.loading = true;
      this.authFacadeService.login(this.loginForm.value)
        .subscribe({
          next: (response) => {
            const [ loginResponse, hierarchyResponse ] = response;
              if ((loginResponse as any)?.mensaje) {
                this.loading = false;
                this.messageService.showInfo(
                  JSON.stringify((loginResponse as any).mensaje),
                  'top right'
                );
              } else {
                const hierarchyEncrypt = this.encryptionService.encryptData(hierarchyResponse);
                this.localStorageService.setItem(HIERARCHY_USER_INFO, hierarchyEncrypt);
                localStorage.setItem('usuario', JSON.stringify(this.loginForm.value));
                localStorage.removeItem('tokenBO');
                localStorage.setItem('usuarioLogin', JSON.stringify(loginResponse.userinfo));
                const roleId = loginResponse.userinfo?.idRoleMenu;
                this.getMenu(roleId);
              }
          },
          error: (error) => {
            this.loading = false;
          }
      })
    } else {
      this.formSubmitted = true;
    }
  }

  getMenu(roleId: number) {
    this.securityService.getMenu(roleId)
      .subscribe({
        next: (response) => {
          this.loading = false;
          this.securityService.setMenuItems(response || []);
          if (response?.length > 0) {
            localStorage.setItem("access", JSON.stringify(response))
          }
          this.goToDashboard();
        },
        error: () => {
          this.loading = false;
          this.goToDashboard();
        }
      })
  }

  goToDashboard() {
    this.router.navigateByUrl('/dashboard');
    this.principalFormService.resetData();
  }

}
