import { Injectable } from "@angular/core";
import { QualitativeCustomerProviderRequestDTO, QualitativeGeneralDataRequestDTO, QualitativeShareholdersBackgroundRequestDTO } from "src/app/pages/qualitative-sheet/models";
import { QualitativeSalesBusinessChannelRequestDTO } from "src/app/pages/qualitative-sheet/models/qualitative-sales-business-channel.request.dto";
import { QualitativeService } from "src/app/services/qualitative.service";
import { ParamsFacade } from "../../params/state/params/params.facade";
import { ParametersEnum } from "src/app/shared/core/enums/parameters.enum";
import { forkJoin } from "rxjs";
import { Store } from "@ngrx/store";
import { selectValidationStep } from "./qualitative-new.selectors";
import { qualitativeValidationStep, qualitativeValidationStepReset } from "./qualitative-new.actions";

@Injectable()
export class QualitativeNewFacade {

    constructor(
        private readonly qualitativeService: QualitativeService,
        private readonly paramsFacade: ParamsFacade,
        private readonly store: Store
    ) {}

    getQualitativeParams() {
        this.paramsFacade.getParams(ParametersEnum.qualitativeBacklog);
        this.paramsFacade.getParams(ParametersEnum.qualitativeActivity);
        this.paramsFacade.getParams(ParametersEnum.qualitativeAccounting);
        this.paramsFacade.getParams(ParametersEnum.qualitativeRawMaterial);
        this.paramsFacade.getParams(ParametersEnum.qualitativeSubSector);
        this.paramsFacade.getParams(ParametersEnum.qualitativeComodities);
        this.paramsFacade.getParams(ParametersEnum.qualitativePaymentSale);
        this.paramsFacade.getParams(ParametersEnum.qualitativePaymentPurchase);
        this.paramsFacade.getParams(ParametersEnum.qualitativeOperationZone);
        this.paramsFacade.getParams(ParametersEnum.qualitativeClientZone);
        this.paramsFacade.getParams(ParametersEnum.qualitativeClientType);
        this.paramsFacade.getParams(ParametersEnum.qualitativeProvidersType);
        this.paramsFacade.getParams(ParametersEnum.qualitativeRole)
    }

    saveGeneralData(request: QualitativeGeneralDataRequestDTO) {
        return this.qualitativeService.saveGeneralData(request);
    }

    saveSalesBusinessChannel(request: QualitativeSalesBusinessChannelRequestDTO) {
        return this.qualitativeService.saveSalesBusinessChannel(request);
    }

    saveCustomerClientProvider(request: QualitativeCustomerProviderRequestDTO) {
        return this.qualitativeService.saveCustomerClientProvider(request);
    }

    saveShareholderBackground(request: QualitativeShareholdersBackgroundRequestDTO) {
        return this.qualitativeService.saveShareholdersBackground(request);
    }

    getMetrics(documentNumber: string, documentType: number) {
        return this.qualitativeService.getMetrics(documentNumber, documentType);
    }

    getQualitativeData(id: number) {
        return forkJoin([
            this.qualitativeService.getGeneralData(id),
            this.qualitativeService.getSalesBusinessChannel(id),
            this.qualitativeService.getCustomerClientProvider(id),
            this.qualitativeService.getShareholdersBackground(id)
        ]);
    }

    selectValidationStep() {
        return this.store.select(selectValidationStep);
    }

    validationStepReset() {
        this.store.dispatch(qualitativeValidationStepReset());
    }

    getValidationStep(id: number) {
        this.store.dispatch(qualitativeValidationStep({ id }));
    }
}