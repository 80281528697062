import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[onlyYear]'
})
export class OnlyYearDirective {

    private regex: RegExp = new RegExp(/^\d{0,4}$/);

    constructor(private el: ElementRef) { }

    @HostListener('input', ['$event'])
    onInput(event: InputEvent) {
        let input = this.el.nativeElement.value;
        if (!this.regex.test(input)) {
            this.el.nativeElement.value = input.slice(0, -1);
        }
    }

    @HostListener('blur', ['$event'])
    onBlur(event: FocusEvent) {
        const input = this.el.nativeElement.value;
        if (!this.isValidYear(input)) {
            this.el.nativeElement.value = '';
        }
    }

    private isValidYear(year: string): boolean {
        const yearNum = parseInt(year, 10);
        return year.length === 4 && yearNum >= 1900 && yearNum <= 3000;
    }



}
