import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { LoginForm } from "src/app/interfaces/login-form.interface";
import { HierarchyRequestDTO, Ilogin } from "src/app/models";
import { HierarchyModel } from "src/app/models/hierarchy.model";
import { AuthService } from "src/app/services/auth.service";
import { ClientSettingsService } from "src/app/services/client-settings.service";

@Injectable({
    providedIn: 'root'
})
export class AuthFacadeService {
    
    constructor( private readonly clientSettingsService: ClientSettingsService,
        private authservice: AuthService
    ) {
        
    }

    login(request: LoginForm): Observable<[Ilogin, HierarchyModel]> {
        const hierarchyRequest: HierarchyRequestDTO = {
            userRegistration: request.username,
            module: 0
          };
        return forkJoin([
            this.authservice.logIn(request),
            this.clientSettingsService.getHierarchy(hierarchyRequest)
        ]);
    }

}